.s-about-quote {
  --borderCircle: var(--commonWhite);
  --colorCircle: var(--danger);
}

.s-about-quote__body {
  position: relative;
  margin-bottom: 14.5rem;
  padding-left: 6.625rem;
  padding-right: 6.625rem;
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.s-about-quote__text {
  position: relative;
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.0046875em;
}

.s-about-quote__background {
  position: absolute;
  top: 67%;
  left: 50%;
  z-index: -10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.s-about-quote__background-red-circle_wrapper {
  margin-bottom: -13.80rem;
}

.s-about-quote__background-red-circle {
  position: relative;
  height: 22.1875rem;
  width: 22.1875rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 9999px;
  background-color: var(--colorCircle);
}

.s-about-quote__background-img {
  position: relative;
  z-index: -10;
  height: 22.1875rem;
  width: 22.1875rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 9999px;
  -o-object-fit: cover;
  object-fit: cover;
}

.s-about-quote__title {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: InterRegularItalic;
  font-size: 12.5rem;
  line-height: 3.5rem;
  letter-spacing: -0.0025em;
}

.s-about-quote__title-img {
  position: absolute;
  top: 50%;
  left: 50%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (max-width: 89.9988em) {
  .s-about-quote__title {
    font-size: 10rem;
  }
}

@media (max-width: 79.9988em) {
  .s-about-quote__title {
    font-size: 7rem;
  }

  .s-about-quote__title-img {
    top: 3rem;
    height: 15rem;
    width: 30rem;
  }
}

@media (max-width: 47.9988em) {
  .s-about-quote__body {
    margin-bottom: 3.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  .s-about-quote__text {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    font-family: InterMedium;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.0083333333em;
  }

  .s-about-quote__background {
    position: static;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 0.625rem;
    --tw-translate-x: -0px;
    --tw-translate-y: -0px;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .s-about-quote__background-red-circle_wrapper {
    margin-bottom: 0px;
    margin-right: -4.25rem;
    border-radius: 9999px;
    border-width: 0.25rem;
    border-style: solid;
    border-color: var(--borderCircle);
  }

  .s-about-quote__background-red-circle {
    height: 8rem;
    width: 8rem;
    mix-blend-mode: multiply;
  }

  .s-about-quote__background-img {
    height: 8rem;
    width: 8rem;
  }

  .s-about-quote__title {
    font-size: 3.5rem;
    line-height: 4rem;
    letter-spacing: -0.0267857143em;
  }

  .s-about-quote__title-img {
    top: 3rem;
    height: 6.125rem;
    width: 14.4375rem;
  }
}

@media (max-width: 35.998em) {
  .s-about-quote__title {
    font-size: 2.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .s-about-quote {
    --borderCircle: var(--commonWhite);
    --colorCircle: var(--danger);
  }
}